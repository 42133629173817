import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

// CSS
import theme from 'styles/theme'

// Components
import Layout from 'components/Layout'
import SEO from 'components/SEO'
import { H2, Image, P, BlueStripe, StyledCustomLink, StyledImg } from 'components/StyledComponents'
import CustomLink from 'components/CustomLink'

// Images
import chevronDown from 'img/chevron_down.svg'
import blueCheck from 'img/blue_check.svg'
import chevronRightOrange from 'img/chevron_right_orange.svg'

// Third Party
import styled from 'styled-components'
import _ from 'lodash'
import parse from 'html-react-parser'

const Home = styled.div``

const StyledBackgroundImage = styled(BackgroundImage)`
  z-index: -20;
  height: 677px;
`

const SingleGradient = styled.div`
  z-index: -15;
  background: rgb(39,39,39);
  background: linear-gradient(87deg, rgba(39,39,39,0.8995973389355743) 30%, rgba(0,0,0,0) 80%);
  ${props => props.height && `height: ${props.height}px;`}
`

const MijnAutoHeeft = styled.div`
  max-width: 430px;
  box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.13);
  border-radius: 3px;
`

const SchadeButton = styled.div`
  transition: 0.25s;
  border-radius: 3px;

  ${P} {
    p {
      margin-bottom: 0;
    }
  }

  :hover {
    background-color: ${theme.colorMain};
    ${H2} {
      color: ${theme.colorTextLight};
    }
    ${P} {
      p {
        color: ${theme.colorTextLight};
      }
    }
  }

  @media (max-width: 450px) {
    ${P} {
      line-height: 22px;
    }
  }
`

const Infoblok = styled.div`
  ${P} {
    p {
      font-size: ${theme.fontSizeSM}px;
      margin-bottom: 0px;
    }
  }

  ${props => props.duo && `
    @media (max-width: 460px) {
      padding-bottom: 100px !important;
    }

    @media (max-width: 375px) {
      padding-bottom: 140px !important;
    }
  `}
`

const ImgButton = styled.div`
  width: 100%;
  bottom: -25px;
  transition: 0.25s;

  :hover {
    transform: scale(1.05);
  }
`

const ImgContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 525px;
  max-height: 325px;
  transition: 0.25s;
  ${props => props.bottom && `bottom: ${props.bottom}px;`}

  ${H2} {
    opacity: 0.5;
    bottom: 7px;
    left: 28px;
    transition: 0.25s;

    ${Image} {
      opacity: 1;
    }
  }

  @media (max-width: 460px) {
    ${H2} {
      font-size: 60px;
    }
  }

  @media (max-width: 375px) {
    ${H2} {
      font-size: 40px;
    }
  }

  :hover {
    ${H2} {
      opacity: 1;
    }
  }
`

const Quote = styled.div`
  p {
    font-size: 22px;
    font-weight: ${theme.fontWeightXl};
    color: ${theme.colorMain};
    margin-bottom: 0;
  }

  ${P} {
    font-size: ${theme.fontSizeM}px;
    color: ${theme.colorTextMain};
  }
`

const USP = styled.div`
`

const TextContainer = styled.div`
  line-height: 20px;
`

const BannerImage = styled.div`
  ${H2} {
    width: 55%;
  }

  @media (max-width: 992px) {
    ${H2} {
      width: 100%;
    }
  }
`

const Quality = styled.div`
  @media (max-width: 576px) {
    ${P} {
      width: 100%;
    }
  }
`

function HomePage() {
  const {
    home: {
      acf: {
        image: {
          localFile: {
            childImageSharp: {
              fluid: BannerImage1
            }
          }
        },
        mijn_auto_heeft: mijnAutoHeeft,
        infoblok_1: Infoblok1,
        infoblok_2: Infoblok2,
        glasgarage_column: GlasGarage,
        image_banner_2: ImageBanner2,
        h1_tag: h1Tag
      },
      yoast_meta: yoast
    }
  } = useStaticQuery(graphql`{
    home: wordpressPage(wordpress_id: {eq: 7}) {
      acf {
        glasgarage_column {
          icon {
            localFile {
              publicURL
            }
          }
          quote {
            from
            text
          }
          usp_list {
            sub_title
            title
            icon {
              localFile {
                publicURL
              }
            }
          }
          text
          title
        }
        image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 2000) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        image_banner_2 {
          text
          cta {
            link {
              target
              title
              url
            }
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        infoblok_1 {
          sub_title
          text
          title
          quality_points {
            quality
          }
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          cta_text {
            target
            title
            url
          }
        }
        infoblok_2 {
          description
          link {
            target
            title
            url
          }
          title
          image {
            localFile {
              childImageSharp {
                fluid(quality: 100, maxWidth: 2000) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        mijn_auto_heeft {
          description
          link {
            target
            url
            title
          }
        }
        h1_tag
      }
      yoast_meta {
        name
        content
        property
      }
      slug
    }
  }`)

  function addGradient(imageProp) {
    const gradient = [
      imageProp,
      `linear-gradient(87deg, rgba(39,39,39,1) 0%, rgba(255,255,255,0) 40%, rgba(255,255,255,0) 60%, rgba(39,39,39,1) 100%)`
    ].reverse();
    return gradient;
  }
  
  return (
    <Layout subFooter>
      <SEO yoast={{ meta: yoast }} />
      <h1 className="d-none">{h1Tag}</h1>
      <Home>
        <StyledBackgroundImage loading="eager" className="position-absolute col-12" fluid={addGradient(BannerImage1)} alt="" />
        <div className="row position-relative pt-sm-0 pt-md-5">
          <div className="container mt-lg-5 px-5 px-md-0 pt-lg-0 pt-sm-0 pt-md-5 d-flex justify-content-md-start justify-content-center">
            <MijnAutoHeeft className="color-background-light mt-4 mt-md-5 pt-2 d-flex flex-wrap justify-content-center">
              <H2 className="color-text-main w-100 mt-2 mb-3 text-center font-weight-xl font-size-xl">
                Mijn auto heeft
                <Image className="pl-3" src={chevronDown} alt="" />
              </H2>
              {_.map(mijnAutoHeeft, (link, index) => (
                <SchadeButton className="w-100 py-2 mx-3 mb-3 color-background-secondary" key={index}>
                  <CustomLink className="w-100" to={link.link.url}>
                    <H2 className="color-main font-size-l pt-4 px-4 font-weight-xl">{link.link.title}</H2>
                    <P className="color-text-main font-size-sm pb-4 px-4 mb-0">{parse(link.description)}</P>
                  </CustomLink>
                </SchadeButton>
              ))}
            </MijnAutoHeeft>
          </div>
        </div>
        <div className="container pt-5 pb-5">
          <div className="row pt-5">
            <Infoblok className="col-12 col-lg-6">
              <BlueStripe className="color-background-main mb-3" />
              <H2 className="font-weight-xl w-lg-49 font-size-xxl">{parse(Infoblok1.title)}</H2>
              <H2 className="font-weight-xl font-size-xm color-main pt-2 pb-3">{parse(Infoblok1.sub_title)}</H2>
              <P className="pr-3">{parse(Infoblok1.text)}</P>
              <Quality className="d-flex justify-content-between flex-wrap py-3">
                {_.map(Infoblok1.quality_points, (quality, index) => (
                  <P className="px-0 font-size-m font-weight-xl" key={index}>
                    <Image className="pr-2" src={blueCheck} alt="" />
                    {quality.quality}
                  </P>
                ))}
              </Quality>
            </Infoblok>
            <div className="col-12 col-lg-6 d-flex pt-3 pt-lg-0 justify-content-center justify-content-lg-end">
              <ImgContainer className="position-relative">
                <StyledImg loading="lazy" fluid={Infoblok1.image.localFile.childImageSharp.fluid} alt="" />
                <ImgButton className="position-absolute d-flex justify-content-center">
                  <StyledCustomLink className="color-background-contrast" to={Infoblok1.cta_text.url}>
                    <P className="color-text-light font-size-ms font-weight-xl">{Infoblok1.cta_text.title}</P>
                  </StyledCustomLink>
                </ImgButton>
              </ImgContainer>
            </div>
          </div>
        </div>
        <div className="my-5 color-background-secondary">
          <div className="container">
            <div className="row pt-4 pb-5 pb-lg-0">
              {_.map(Infoblok2, (item, index) => (
                <Infoblok duo key={index} className="col-12 col-lg-6 py-5 mb-5 mb-lg-0 py-lg-0 d-flex justify-content-center flex-wrap">
                  <H2 className="font-weight-xl text-center pb-4 font-size-xxl">{item.title}</H2>
                  <P className="px-0 px-md-5 px-lg-4 mx-0 mx-md-5 mx-lg-0">{parse(item.description)}</P>
                  <ImgContainer bottom={-50} className="position-relative">
                    <CustomLink to={item.link.url}>
                      <StyledImg loading="lazy" fluid={item.image.localFile.childImageSharp.fluid} alt="" />
                      <H2 className="position-absolute font-size-xxxl font-weight-xl color-text-light">
                        {item.title}
                        <Image className="pl-3" src={chevronRightOrange} alt="" />
                      </H2>
                    </CustomLink>
                  </ImgContainer>
                </Infoblok>
              ))}
            </div>
          </div>
        </div>
        <div className="my-5 color-background-light">
          <div className="container pt-5">
            <div className="row py-5">
              <div className="col-12 pl-5 col-lg-8">
                <BlueStripe className="color-background-main mb-2" />
                <H2 className="font-size-xxl pb-3 font-weight-xl">{GlasGarage.title}</H2>
                <Quote>
                  {parse(GlasGarage.quote.text)}
                  <P className="pt-2">{`- ${GlasGarage.quote.from}`}</P>
                </Quote>
                <div className="row pt-5 d-flex">
                  <div className="col-12 col-md-3 d-flex justify-content-center">
                    <Image height={156} width={118} src={GlasGarage.icon.localFile.publicURL} alt="" />
                  </div>
                  <div className="col-12 col-md-9">
                    <div className="font-size-sm pr-5">
                      {parse(GlasGarage.text)}
                      <CustomLink className="color-text-contrast font-size-m font-weight-xl" to="/autoglaz">
                        Lees verder
                        <Image height={10} width={14} src={chevronRightOrange} alt="" />
                      </CustomLink>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-4 pt-5">
                {_.map(GlasGarage.usp_list, (usp, index) => (
                  <USP key={index} className="row d-flex py-4 align-items-center">
                    <div className="col-3 d-flex justify-content-center">
                      <Image src={usp.icon.localFile.publicURL} alt="" />
                    </div>
                    <div className="col-9">
                      <TextContainer>
                        <H2 className="font-size-m font-weight-xl">{usp.title}</H2>
                        <P className="font-size-sm">{usp.sub_title}</P>
                      </TextContainer>
                    </div>
                  </USP>
                ))}
              </div>
            </div>
          </div>
        </div>
        <BannerImage className="row position-relative pt-4">
          <StyledBackgroundImage loading="lazy" className="h-100 position-absolute col-12" fluid={ImageBanner2.image.localFile.childImageSharp.fluid} alt="" />
          <SingleGradient className="h-100 position-absolute col-12" />
          <div className="container py-5">
            <H2 className="font-size-xl px-2 text-center text-lg-left px-lg-0 pt-5 pb-5 font-weight-xl color-text-light">{ImageBanner2.text}</H2>
            <div className="d-flex justify-content-center justify-content-lg-start pb-5">
              <StyledCustomLink hover className="color-background-contrast" to={ImageBanner2.cta.link.url}>
                <P className="color-text-light font-size-ms font-weight-xl">{ImageBanner2.cta.link.title}</P>
              </StyledCustomLink>
            </div>
          </div>
        </BannerImage>
      </Home>
    </Layout>
  )
}

export default HomePage